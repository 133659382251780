import React from "react";
import CustomTopBar from "../../../components/Common/CustomTopBar";
import RequestDemoForm from "./RequestDemoForm";
import { Container } from "reactstrap";
import Banner from "../RequestDemo/Banner";
import '../../../assets/scss/custom/pages/_requestDemo.scss';


const RequestDemo = () => {
  return (
    <>
      <CustomTopBar />
      <Banner />  {/* Adding the banner here */}
      <Container style={{ paddingTop: "80px" }}>
        <RequestDemoForm />
      </Container>
    </>
  );
};

export default RequestDemo;
