const packages = [
  'RSVP',
  'RSVP & Ticketing',
  'RSVP, Event Check In , Badging Solution & Mobile App Access',
  'RSVP, Ticketing, Event Check In, Badging Solution & Mobile App Access',
  'Exhibition',
  'Virtual Event',
  
];

export default packages;
