import React from "react";
import useCustomQuery from "../../Hooks/useCustomQuery";
import { useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import LoaderComingSoon from "../../components/Loader/LoaderComingSoon";
import PublicEventOne from "../../components/Common/EventLanding/PublicEventOne";
import CustomFooter from "../../Layout/HorizontalLayout/CustomFooter";
// import EventLandingPublic from "./Components/Social/EventLandingCustom";

const EventLandingCustomPublic = () => {
  document.title = "Kago Event | Event Landing";
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const token = obj?.data?.token;
  const params = useParams();
  const eventID = params.eventID;

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isLoadingGetMyEventDetail,
  } = useCustomQuery(
    ["myEventDetail", eventID],
    `${process.env.REACT_APP_API}guest/events/${eventID}`,
    null
  );

  if (isLoadingGetMyEventDetail) {
    <div className="mt-5 d-flex justify-content-center align-items-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>;
  }

  const eventType = getMyEventDetail?.data?.eventType;
  const eventStatus = getMyEventDetail?.data?.state;

  return (
    <React.Fragment>
      <div
        style={{
          width: "100%",
          height: "100vh",
          position: "relative",
          background:
            eventStatus === "Not Published" ? "#f68a20" : "transparent",
        }}
      >
        {eventStatus === "Not Published" ? (
          <div
            className="w-100"
            style={{ position: "relative", height: "80vh" }}
          >
            <LoaderComingSoon />
          </div>
        ) : null}
        {eventStatus === "Published" ? (
          <div
            className="w-100"
            style={{ position: "relative", height: "100vh" }}
          >
            <PublicEventOne
              eventDetails={getMyEventDetail?.data}
              token={token}
              eventID={eventID}
            />
          </div>
        ) : null}
        <div className="w-100">
          <CustomFooter />
        </div>
      </div>
    </React.Fragment>
  );
};

export default EventLandingCustomPublic;
