import React, { useRef, useState, useEffect } from "react";
import useCustomMutation from "../../Hooks/useCustomMutation";
import ImagePlaceHolder from "../../assets/images/community/Community.png";
import { FaPlus, FaEdit } from "react-icons/fa";
import ButtonSubmit from "../Buttons/ButtonSubmit";
import { Alert } from "reactstrap";

const BannerEvent = ({ userID, token, eventDetails }) => {
  const [showImage, setShowImage] = useState(null);
  const [uploadImg, setUploadImg] = useState(null);
  const [showAddIcon, setShowAddIcon] = useState(true);
  const [hovering, setHovering] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const key = ["EventDetails", eventDetails?._id];
  const mutation = useCustomMutation(key);

  const fileInputRef = useRef(null);
  const handleDivClick = () => {
    fileInputRef.current.click();
  };
  const handleUploadImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadImg(event.target.files[0]);
      const reader = new FileReader();
      reader.onload = (e) => {
        setShowImage(e.target.result);
      };
      reader.readAsDataURL(file);
      setShowWarning(false);
    }
  };

  const toggleSaveChanges = (e) => {
    e.preventDefault();
    if (!uploadImg) {
      setShowWarning(true);
      return;
    }
    setShowWarning(false);
    const Method = "PATCH",
      url = `events/${eventDetails?._id}`,
      isJSON = true;
    var formdata = new FormData();
    if (uploadImg) {
      formdata.append("bannerImage", uploadImg);
    }
    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Event Banner successfully updated",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (eventDetails) {
      const { bannerImage } = eventDetails;
      setShowImage(bannerImage);
    }
  }, [eventDetails]);

  return (
    <div>
      {showWarning && (
        <div
          style={{
            width: 400,
          }}
        >
          <Alert
            color="warning"
            className="w-100 mt-3 d-flex justify-content-center align-items-center"
          >
            <p> Please upload an image before updating the banner.</p>
          </Alert>
        </div>
      )}
      <div
        style={{
          width: 400,
          height: 170,
          position: "relative",
          userSelect: "none",
          objectFit: "contain",
        }}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <img
          src={showImage ? showImage : ImagePlaceHolder}
          className="w-100 h-100 rounded-3"
          style={{
            position: "absolute",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "#ececec",
          }}
        />
        {showAddIcon && (
          <div
            onClick={handleDivClick}
            style={{
              position: "absolute",
              top: "35%",
              left: "40%",
              backgroundColor: "white",
              width: 40,
              height: 40,
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
              cursor: "pointer",
            }}
          >
            <FaPlus size={20} color="black" />
          </div>
        )}
        {hovering && !showAddIcon && (
          <div
            onClick={handleDivClick}
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              cursor: "pointer",
            }}
          >
            <div
              className=""
              style={{
                width: 40,
                height: 40,
                borderRadius: "50%",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaEdit size={20} color="black" />
            </div>
          </div>
        )}
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleUploadImage}
          style={{ display: "none" }}
          accept="image/*"
        />
      </div>
      <div className="mt-3 w-100 d-flex">
        <ButtonSubmit
          Title="Update Banner"
          ColorText="white"
          BorderColor="#F68A20"
          BackgroundColor="#F68A20"
          borderRadius={25}
          handleOnclick={toggleSaveChanges}
          pending={mutation?.isLoading}
        />
      </div>
    </div>
  );
};

export default BannerEvent;
