import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

const DemoRequestInput = ({ label, required, ...rest }) => (
  <FormGroup>
    <Label>
      {label}
      {/* Remove the star icon */}
      {/* {required && <FaStar style={{ color: "red" }} />} */}
    </Label>
    <Input {...rest} />
  </FormGroup>
);

export default DemoRequestInput;
