import React from "react";

import readDate from "../../../../utils/readDate";

import "./viewTicket.scss";

const ViewTicket = ({ details }) => {
  const startDate = readDate(details?.createdAt);
  const endDate = readDate(details?.closingDate);
  const startEarlyBirdDate = readDate(details?.earlyBirdStartDate);
  const endEarlyBirdDate = readDate(details?.earlyBirdEndDate);

  return (
    <div className="w-100">
      <div className="ticket">
        <div className="ticket--start">
          <div className="ticket--right--container"></div>
        </div>
        <div className="ticket--center">
          <div className="ticket--center--row">
            <div className="ticket--center--col">
              <span>Ticket</span>
              <strong>{details?.tier}</strong>
            </div>
            <div className="ticket--center--col">
              <span>Price</span>
              <strong>R {details?.price}</strong>
            </div>
          </div>
          <div className="ticket--center--row">
            <div className="ticket--center--col">
              <span className="ticket--info--title">Start Date</span>
              <span className="ticket--info--subtitle">{startDate}</span>
              {/* <span className="ticket--info--content">
                7:00 am to 9:00 pm (GMT+1)
              </span> */}
            </div>
            <div className="ticket--center--col">
              <span className="ticket--info--title">Closing Date</span>
              <span className="ticket--info--subtitle">{endDate}</span>
              {/* <span className="ticket--info--content">
                Location complete address, Town, COUNTRY
              </span> */}
            </div>
          </div>
          <div className="ticket--center--row">
            <div className="ticket--center--col">
              <span className="ticket--info--title">Sold Ticket(s)</span>
              <span className="ticket--info--content">
                {details?.quantitySold} /{details?.quantity}
              </span>
            </div>
            <div className="ticket--center--col">
              <span className="ticket--info--title">Early Bird discount</span>
              <div className="ticket--info--content">
                <div>{details?.discount ? details?.discount : 0}%</div>
              </div>
            </div>
          </div>
        </div>
        <div className="ticket--end">
          <div>
            {details?.allowEarlyBird ? (
              <>
                <div className="ticket--info--earlyBird--title">
                  Early Bird Start Date
                </div>
                <div className="ticket--info--title" style={{ color: "white" }}>
                  {startEarlyBirdDate}
                </div>
                <div className="ticket--info--earlyBird--title">
                  Early Bird End Date
                </div>
                <div className="ticket--info--title" style={{ color: "white" }}>
                  {endEarlyBirdDate}
                </div>
                <div className="ticket--info--earlyBird--title">
                  Early Bird Price
                </div>
                <div className="ticket--info--title" style={{ color: "white" }}>
                  R {details?.tierEarlyBirdPrice}
                </div>
              </>
            ) : null}

            <div className=" mt-2 ticket--info--earlyBird--title">
              Last Update
            </div>
            <div className="ticket--info--title" style={{ color: "white" }}>
              {details?.userId?.firstName} {details?.userId?.lastName}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <div className="ticket--info--title" style={{ color: "black" }}>
          Terms Ticket
        </div>
        <div className="ticket--info--content">{details?.ticketTerms}</div>
      </div>
    </div>
  );
};
export default ViewTicket;
