import React from 'react';

const Banner = () => {
  return (
    <div className="banner" style={{ marginTop: "50px" }}> {/* Added margin-top for spacing */}
      <div className="banner-content">
        <h1 style = {{fontFamily:"poppins"}}> Discover Kago Events</h1>
        <p>
          Manage virtual and in-person events effortlessly with our all-in-one platform.
        </p>
        <p>
          Simplify your event planning, from registrations to real-time tracking, and deliver unforgettable experiences.
        </p>
      </div>
    </div>
  );
};

export default Banner;
