import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

const HomePage = (props) => {
  document.title = "Kago Events | Home";
  return (
    <div
      className="home"
      style={{ backgroundColor: "#f9c393" }} // Main background color
    >
      <div className="home--wrapper text-center">
        <header className="home--header">
          <h1>Welcome to Kago Events</h1>
          <p>Your premier platform for managing virtual, hybrid, and in-person events.</p>
        </header>

        <section className="home--features mt-4">
          <h2>Features</h2>
          <ul className="list-unstyled">
            <li>🗓️ Easy registration with QR codes</li>
            <li>📈 Comprehensive marketing services</li>
            <li>📊 Real-time monitoring and reporting tools</li>
            <li>🌐 Seamless integration for all event types</li>
          </ul>
        </section>

        <section className="home--call-to-action mt-4">
          <h2>Join Us Today!</h2>
          <Link to="/login" className="btn btn-primary">
            Log In
          </Link>
          <Link to="/register-attendee" className="btn btn-secondary ml-2">
            Sign Up
          </Link>
        </section>

        <section className="home--recent-events mt-4">
          <h2>Upcoming Events</h2>
          <p>Check out our latest events and join the excitement!</p>
          {/* You can add a component here to display recent events */}
        </section>
      </div>
      <footer className="home-brandMark text-center mt-4">
        <Link
          to="https://www.tngsolutions.co.za/"
          target="_blank"
          rel="noopener noreferrer"
          className="brand-mark"
        >
          By TNG Solutions | Developed in South Africa
        </Link>
      </footer>
    </div>
  );
};

export default withRouter(HomePage);

HomePage.propTypes = {
  history: PropTypes.object,
};
