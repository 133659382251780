import React, { useState } from "react";
import packageDescriptions from "./packageDescriptions";

const FAQs = () => {
  const [openFAQ, setOpenFAQ] = useState(null); // State to track open FAQ

  const faqs = [
    {
      question: "What is Kago?",
      answer:
        "Kago is an event management platform that simplifies the process of organizing virtual, and in-person events.",
    },
    {
      question: "What types of events can I manage with Kago?",
      answer:
        "You can manage exhibitions, conferences, workshops, and more using Kago.",
    },
    {
      question: "How can I request a demo?",
      answer:
        "Fill out the request form above, and our team will get back to you to schedule a demo.",
    },
    {
      question: "Are there any package options?",
      answer:
        "Yes, we offer various packages tailored to different event needs. Below are the available packages along with their features:",
    },
    {
      question: "What does the RSVP package include?",
      answer: packageDescriptions.find(pkg => pkg.name === 'RSVP'),
    },
    {
      question: "What does the RSVP & Ticketing package include?",
      answer: packageDescriptions.find(pkg => pkg.name === 'RSVP & Ticketing'),
    },
    {
      question: "What does the RSVP, Event Check In , Badging Solution & Mobile App Access package include?",
      answer: packageDescriptions.find(pkg => pkg.name === 'RSVP, Event Check In , Badging Solution & Mobile App Access'),
    },
    {
      question: "What does the RSVP, Ticketing, Event Check In , Badging Solution & Mobile App Access package include?",
      answer: packageDescriptions.find(pkg => pkg.name === 'RSVP, Ticketing, Event Check In, Badging Solution & Mobile App Access'),
    },
    {
      question: "What does the Exhibition package include?",
      answer: packageDescriptions.find(pkg => pkg.name === 'Exhibition'),
    },
    {
      question: "What does the Virtual Event package include?",
      answer: packageDescriptions.find(pkg => pkg.name === 'Virtual Event'),
    },
   
  ];

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index); // Toggle FAQ open/close
  };

  const renderAnswer = (answer) => {
    if (answer && answer.features) {
      // If the answer contains package features, render them as a list
      return (
        <ul>
          {answer.features.map((feature, index) => (
            <li key={index} style={{ listStyleType: "none", marginLeft: "-20px" }}>
              {feature}
            </li>
          ))}
        </ul>
      );
    }
    return <p>{answer}</p>; // Render plain text answer if not a package
  };

  return (
    <div className="faq-container">
      <h2 className="faq-heading">Frequently Asked Questions</h2>
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div
            className="faq-question"
            onClick={() => toggleFAQ(index)}
            style={{ cursor: "pointer", color: "orange", fontWeight: "bold" }}
          >
            {faq.question}
          </div>
          <div
            className={`faq-answer ${openFAQ === index ? "open" : ""}`}
            style={{
              overflow: "hidden",
              maxHeight: openFAQ === index ? "500px" : "0",
              transition: "max-height 0.5s ease-out",
            }}
          >
            {renderAnswer(faq.answer)} {/* Use renderAnswer to handle both text and package data */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQs;
