import React, { useState } from "react";
import { Row, Col, Alert } from "reactstrap";
import PackageList from "./packageList";
import FAQ from "./FAQ";
import DemoRequestInput from "./DemoRequestInput";
import packageDescriptions from "./packageDescriptions";
import packages from "./packages";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import ButtonSubmit from "../../../components/Buttons/ButtonSubmit";
import '../../../assets/scss/custom/pages/_requestDemo.scss';

const RequestDemoForm = () => {
  const [organizer, setOrganizer] = useState({
    name: "",
    email: "",
    company: "",
    eventType: "",
    otherEventType: "",
    organizationType: "",
    otherOrganizationType: "",
    contactNumber: "",
    packages: [],
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // **Handle Change for Form Inputs**
  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setOrganizer((prev) => {
      if (type === "checkbox") {
        const newPackages = checked
          ? [...prev.packages, value]
          : prev.packages.filter((pkg) => pkg !== value);
        return {
          ...prev,
          packages: newPackages,
        };
      } else {
        return {
          ...prev,
          [name]: value,
        };
      }
    });
  };

  // **Handle Form Submission**
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !organizer.name ||
      !organizer.email ||
      !organizer.company ||
      !organizer.eventType ||
      (organizer.eventType === "other" && !organizer.otherEventType) ||
      !organizer.contactNumber ||
      !organizer.organizationType ||
      (organizer.organizationType === "other" && !organizer.otherOrganizationType)
    ) {
      setErrorMessage("Please fill in all required fields marked with a star.");
      return;
    }
    setErrorMessage("");
    setLoading(true);
    console.log("Organizer Info: ", organizer);
    // TODO: Handle form submission logic here (e.g., API call)
    setLoading(false);
  };

  return (
    <Row className="request-demo-container">
      {/* Package Selection Column */}
      <Col md="6" className="request-demo-column">
        <PackageList
          packages={packages}
          packageDescriptions={packageDescriptions}
          handleChange={handleChange} // Corrected prop name
          organizer={organizer} // Passed organizer state
        />
      </Col>

      {/* Form Column */}
      <Col md="6" className="request-demo-column">
        <h4 className="form-heading">Request a Demo</h4>
        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
        <hr />
        <form onSubmit={handleSubmit}>
          {/* Organizer Name */}
          <DemoRequestInput
            label={
              <>
                <span>Organizer Name</span>
                <span className="required-star"> *</span>
              </>
            }
            name="name"
            value={organizer.name}
            onChange={handleChange}
            required
          />
          {/* Email */}
          <DemoRequestInput
            label={
              <>
                <span>Email</span>
                <span className="required-star"> *</span>
              </>
            }
            type="email"
            name="email"
            value={organizer.email}
            onChange={handleChange}
            required
          />
          {/* Company Name */}
          <DemoRequestInput
            label={
              <>
                <span>Company Name</span>
                <span className="required-star"> *</span>
              </>
            }
            name="company"
            value={organizer.company}
            onChange={handleChange}
            required
          />
          {/* Contact Number */}
          <div className="form-group">
            <label>
              <span>Contact Number</span>
              <span className="required-star"> *</span>
            </label>
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              defaultCountry="ZA"
              value={organizer.contactNumber}
              onChange={(value) =>
                setOrganizer({ ...organizer, contactNumber: value })
              }
              className="custom-phone-input"
              required
            />
          </div>
          {/* Organisation Type */}
          <DemoRequestInput
            label={
              <>
                Organisation Type <span className="required-star">*</span>
              </>
            }
            type="select"
            name="organizationType"
            value={organizer.organizationType}
            onChange={handleChange}
            required
          >
            <option value="">Select organisation type</option>
            <option value="corporate">Corporate</option>
            <option value="nonProfit">Non-Profit</option>
            <option value="government">Government</option>
            <option value="education">Education</option>
            <option value="other">Other</option>
          </DemoRequestInput>
          {organizer.organizationType === "other" && (
            <DemoRequestInput
              label="Please specify:"
              name="otherOrganizationType"
              value={organizer.otherOrganizationType}
              onChange={handleChange}
              required
            />
          )}
          {/* Event Type */}
          <DemoRequestInput
            label={
              <>
                <span>Event Type</span>
                <span className="required-star"> *</span>
              </>
            }
            type="select"
            name="eventType"
            value={organizer.eventType}
            onChange={handleChange}
            required
          >
            <option value="">Select event type</option>
            <option value="exhibition">Exhibition</option>
            <option value="conference">Conference</option>
            <option value="techMeetup">Tech Meet Up</option>
            <option value="workshop">Workshop</option>
            <option value="networkFair">Network Fair</option>
            <option value="other">Other</option>
          </DemoRequestInput>
          {organizer.eventType === "other" && (
            <DemoRequestInput
              label="Please specify:"
              name="otherEventType"
              value={organizer.otherEventType}
              onChange={handleChange}
              required
            />
          )}

          <div className="d-grid mb-2">
            <ButtonSubmit
              Title={loading ? "Submitting..." : "Request Demo"}
              BackgroundColor="#f68a20"
              ColorText="#fff"
              BorderColor=""
              borderRadius={10}
              handleOnclick={handleSubmit}
              pending={loading}
            />
          </div>
        </form>
      </Col>
      <Col md="12">
        {/* FAQ Section below the Request Demo Button */}
        <div className="faq-section mt-4">
          <FAQ />
        </div>
      </Col>
    </Row>
  );
};

export default RequestDemoForm;
