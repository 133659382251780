import React, { useState } from "react";
import { FormGroup, Label, Collapse } from "reactstrap";
import { FaCaretDown, FaCheckCircle } from "react-icons/fa"; // Import the dropbox and check circle icons

const PackageList = ({ packages, organizer, handleChange, packageDescriptions }) => {
  const [openPackage, setOpenPackage] = useState(null);

  const togglePackage = (pkg) => {
    if (openPackage === pkg) {
      setOpenPackage(null); // Close if already open
    } else {
      setOpenPackage(pkg); // Open the clicked package
    }
  };

  // Find the corresponding description for the package
  const getDescription = (pkg) => {
    const packageData = packageDescriptions.find((p) => p.name === pkg);
    return packageData ? (
      <ul className="package-description-list">
        {packageData.features.map((feature, index) => (
          <li key={index} className="package-description-item">
            <FaCheckCircle /> {/* Orange tick icon */}
            {feature}
          </li>
        ))}
      </ul>
    ) : (
      "No description available."
    );
  };

  return (
    <FormGroup>
      <h4 className="form-heading">Select Offered Packages</h4>
      {packages.map((pkg) => {
        const sanitizedId = `Package-${pkg.replace(/[^a-zA-Z0-9_-]/g, "-")}`;
        const description = getDescription(pkg); // Get the package description

        return (
          <div key={pkg} className="package-display-container">
            <div className="d-flex justify-content-between align-items-center package-card">
              <div className="d-flex align-items-center">
                <Label
                  for={sanitizedId}
                  onClick={() => togglePackage(pkg)} // Toggle dropdown on label click
                  className="package-label"
                >
                  {pkg}
                </Label>
              </div>
              {/* Drop down icon */}
              <FaCaretDown
                onClick={() => togglePackage(pkg)}
                style={{ cursor: "pointer" }}
              />
            </div>
            <Collapse isOpen={openPackage === pkg}>
              <div
                id={`${sanitizedId}-desc`}
                className="package-description"
              >
                {description} {/* Display the description as a list */}
              </div>
            </Collapse>
          </div>
        );
      })}
    </FormGroup>
  );
};

export default PackageList;
