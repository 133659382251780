import React from "react";
import { useNavigate } from "react-router-dom";
import { Col } from "reactstrap";
import ButtonSubmit from "../../../components/Buttons/ButtonStyle";
import Nothing from "../../../components/Loader/Contacts";
import ImagePlaceHolder from "../../../assets/images/placeholder/ImagePlaceHolder.png";
import LoaderEventItem from "../../../components/Loader/LoaderEventItem";

const AllEventPersonnelItems = ({
  userEmail,
  filteredEvents,
  isLoadingGetEvents,
  isPersonnelEmpty,
}) => {
  const Navigate = useNavigate();
  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const checkForEmail = (personnelArray, targetEmail) => {
    return personnelArray?.some((person) => person?.email === targetEmail);
  };

  const handleAttendeeEnterEvent = (eventName, evID) => {
    Navigate(`/${eventName?.replace(/ /g, "-")}/event-landing/${evID}`);
  };
  const handleEditEvent = (eventName, evID) => {
    Navigate(`/${eventName?.replace(/ /g, "-")}/set/${evID}`);
  };

  if (isPersonnelEmpty) {
    return (
      <div className="h1">
        <Nothing title="You have no event at the moment" />
      </div>
    );
  }

  if (isLoadingGetEvents) {
    return <LoaderEventItem />;
  }
  return (
    <>
      {filteredEvents?.map((item, index) => {
        let dateValue = new Date(item.startDate);
        let day = dayNames[dateValue.getUTCDay()];
        let month = monthNames[dateValue.getUTCMonth()];
        let year = dateValue.getUTCFullYear();
        let date = dateValue.getUTCDate();

        let formattedDate = `${day} ${date} ${month} ${year}`;
        const isAdmin = checkForEmail(item?.personnel, `${userEmail}`);

        return (
          <>
            <Col xs={12} sm={12} md={12} lg={12} key={index}>
              <div className="d-flex p-3 mb-4 shadow-sm bg-white card-brd myEvent-card-container">
                <div
                  className="d-flex align-items-center"
                  style={{ flex: 0.4 }}
                >
                  <div className="MyEvent_Parent_div">
                    <div className="MyEvent_image_div">
                      <img
                        src={
                          item?.bannerImage
                            ? item?.bannerImage
                            : ImagePlaceHolder
                        }
                        width="100%"
                        height="100%"
                        className="card-brd"
                        alt="event"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-100 mx-2">
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: "600",
                      color: "black",
                    }}
                  >
                    {item?.eventName}
                  </div>
                  <div
                    className="mt-2"
                    style={{
                      fontSize: 13,
                    }}
                  >
                    {item?.eventDescription &&
                    item?.eventDescription.length > 35
                      ? `${item?.eventDescription?.substring(0, 200)}...`
                      : null}
                    {item?.eventDescription &&
                    item?.eventDescription.length <= 35
                      ? item?.eventDescription
                      : null}
                  </div>
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: "600",
                      color: "black",
                    }}
                  >
                    {formattedDate} |{" "}
                    {`${item.startTime} - ${item.endTime} SAST`}
                  </div>

                  <div
                    className="mt-2 mb-1"
                    style={{
                      fontSize: 13,
                    }}
                  >
                    <ButtonSubmit
                      Title="Visit Event"
                      ColorText="#F68A20"
                      BorderColor="#F68A20"
                      BackgroundColor="white"
                      borderRadius="25px"
                      handleOnclick={() =>
                        handleAttendeeEnterEvent(item?.eventName, item?._id)
                      }
                    />
                  </div>
                  {isAdmin && (
                    <>
                      <div
                        style={{
                          fontSize: 14,
                          textDecoration: "underline",
                          color: "#0e35dd",
                          fontWeight: "500",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleEditEvent(item?.eventName, item?._id)
                        }
                      >
                        Edit Event
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Col>
          </>
        );
      })}
    </>
  );
};

export default AllEventPersonnelItems;
