import React from "react";
import useCustomQuery from "../../../Hooks/useCustomQuery";
import Communities from "./Communities";
import AllEventPersonnelItems from "./AllEventPersonnelItems";

function AllEvents({ search, userId, userEmail, token, t, isSwitchOn }) {
  const {
    data: getEvents,
    error: errorGetEvents,
    isLoading: isLoadingGetEvents,
  } = useCustomQuery(
    ["MyEvents", userEmail],
    `${process.env.REACT_APP_API}events/personnel?email=${userEmail}`,
    token
  );

  const {
    data: getEventsAsAttendee,
    error: errorGetEventsAsAttendee,
    isLoading: isLoadingGetEventsAsAttendee,
  } = useCustomQuery(
    ["MyEventsAsAttendee", userEmail],
    `${process.env.REACT_APP_API}events/attendee-events?email=${userEmail}`,
    token
  );

  console.log("****", JSON.stringify(getEventsAsAttendee, null, 2));

  const filterEventsByName = (events, searchQuery, isSwitchOnQuery) => {
    if (isSwitchOnQuery) {
      return events?.filter((event) =>
        event?.eventName?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else {
      if (!searchQuery) {
        const myEvent = [];
        return myEvent;
      } else {
        return events?.filter((event) =>
          event?.eventName?.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
    }
  };
  const filteredEvents = filterEventsByName(
    getEvents?.data,
    search,
    isSwitchOn
  );

  const isViewEvent = isSwitchOn || search;

  return (
    <>
      {!isViewEvent ? (
        <>
          <Communities token={token} userEmail={userEmail} />
        </>
      ) : (
        <>
          <AllEventPersonnelItems
            userEmail={userEmail}
            filteredEvents={filteredEvents}
            isLoadingGetEvents={isLoadingGetEvents}
            isPersonnelEmpty={
              getEvents?.data?.length === 0 && getEvents?.status === "ok"
            }
          />
        </>
      )}
    </>
  );
}

export default AllEvents;
